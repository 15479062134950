.form_container{
    display: inline-grid;
    padding:5px;
}

.container_btns{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    margin: 15px 0px;
}

.pdf_btn_download{
    height: 44px;
    background-color: rgb(161, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    width: 120px;
}

.pdf_btn_download a{
    text-decoration: none;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.btn_invia_mail{
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    font-weight: bold;
    width: 120px;
    border: 2px solid dodgerblue;
    color: white;
    background: dodgerblue;
}

.btn_chiudi{
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    font-weight: bold;
    width: 120px;
    background: transparent;
    color: black;
    border: 2px solid;
    margin: 0px
}

.btn_salva_mail_predefinito{
    height: 44px;
    width: 200px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .container_btns{
        flex-direction: column-reverse;
        align-items: center;

    }

    .container_btns button, .container_btns div {
        margin: 15px;
        width: 60%;
    }
}