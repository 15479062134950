.Alert_container_blur{
    background-color: rgba(50,50,50,0.7);
    backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:200
}

.Alert_container{
    background-color: #F6F9FF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--Font);
    padding: 30px;
}

.container_btn{
    display: inline-flex;
}

.btn{
    width: 80px;
    height: 35px;
    margin: 10px;
}

.btn:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    /* Lowering the shadow */
}

.btn_ok{
    background-color: rgb(164, 229, 164);
    font-weight: bold;
}

.btn_modifica{
    background-color: rgb(255, 214, 0);
}

.btn_annulla{
    background-color: rgb(255, 255, 255);
    border: solid 1px black;
}

.Attenzione{
    border: 2px solid red;
    color: red;
}
