.Pagina{
    height: 90vh;
    width:calc(90vh/1.41) ;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 10px;
    background-color: white;
    border: 1px black solid;
}

.intestazione {
    height: 15%; 
    width: 100%;
    display: flex;
    border-bottom: 1px solid black;
}

.logoContainer {
    height:85%;
}

.logo{
    height: 100%;
    width: auto;
}

.infoAziendaContainer{
    text-align: right;  
    flex-direction: row;           	
    width: 100%;
    line-height: 0.8;
}

.nomeAzienda{
    font-family: Helvetica;
    font-weight: bold;
    font-size: 16px;
    margin-top: 0px;
}

.datiFatturaContainer {
    width: 100%;
    display: flex;
    border-bottom: 1px solid black;
    padding-top: 20px;
    padding-bottom: 20px;
}

.datiFattura {
    width:50%; 
    text-align: left;
}

.datiCliente {
    width:50%; 
    text-align: right;
}

.regular{
    font-family: Helvetica;
    font-size:  12px;
    font-weight:100
}

.bold{
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px;
}

/* --- Tabella ---*/
.tabellaContainer {
    height: 60%; 
    width: 100%;
    display: flex;
    flex-direction: column;
}

.head {
    flex-direction: row; 
    display: flex;
    width: 100%;
    font-weight: bold;
}

.descrizione{
    width: 60% ;
    text-align: left;
}

.quantita{
    width: 10% ;
    text-align: center;
}

.prezzoU{
    width: 15%;
    text-align: center;
}

.prezzoT{
    width: 15%;
    text-align: center;
}

.row{
    display: flex;
    width: 100%;
    flex-direction:row;
    background-color: rgba(240,240,240,1); 
    border-bottom: 1px solid black;
    overflow: hidden;
    height: 22px;
}

.lightrow{
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: white;
    border-bottom: 1px solid black 
}

.resoconto {width: 100%; 
    flex-direction: row;
    display: flex;
    justify-content: end;
    border-top: 1px solid black;
}

.vuoto {width: 60%; flex-direction: row;
}

.resocontoDescrizioni{width: 30%; text-align:left;
}

.resocontoImporti {width: 10%; text-align: right;
}
/*-------------------------------------------*/

.noteContainer{
    height: 11%;
    width: 100%;
    bottom: 20px;
    text-align: left;
    word-wrap: break-word;
}

.piePagina{
    height: 7%;
    bottom: 0px; 
    width: 100%;
    border-top: 1px solid black;
    margin-bottom: 0px; 
    text-align: justify;

}

p{
    margin: 0px;
    line-height: 1;
}

/*---------------------MOBILE----------------------*/

@media screen and (max-width: 600px) {
    .resocontoDescrizioni{
        width: 40%;
    }
    .resocontoImporti{
        width: 20%;
    }
    .head{
        font-size: 9pt;
    }
}