.Sfondo_blur{
    background-color: rgba(50,50,50,0.7);
    backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Finestra_container{
    background-color: #F6F9FF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--Font);
    padding: 30px;
    height: fit-content;
    overflow: scroll;


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.Finestra_container::-webkit-scrollbar {
    display: none;
}
  

.label_input{
    font-weight:bold;
    margin:5px;
}

.input_text{
    -webkit-appearance: none;
    font-style: italic;
    margin-bottom: 10px;
    width: -webkit-fill-available;
    height: 44px;
    border-radius: 0px;
    border: 1px solid #dfe1e5;
    outline: none;
    padding: 0px 15px;
}

.input_text:focus {    
    border: 1px solid #dfe1e5;
    outline: none;
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }

.input_number{
    font-style: italic;
    margin: auto;
}

.conferma_btn{
    font-weight: bold;
}

h1{
    margin-top: 0px;
}

@media screen and (max-width: 600px) {
    .Finestra_container{
        height: 100%;
        width: 100%;
    }

    h1{
        margin-top: revert;
    }
}
