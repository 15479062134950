.notaContainer{
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 500px;
}

.areaNota{
    padding: 15px;
    resize: none;
}

.btnAggiungi{
    width: 180px;
    align-self:center;
    border: 1px solid black;
    justify-content: center;
}
.btnElimina{
    width: 10%;
    height: 44px;
}
.selectNota{
    width: 90%;
    padding: 5px;
}

.btnNoteContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.radio_container{
    padding-top: 20px;
}
@media only screen and (max-width: 600px) {
    .notaContainer{
        width: auto;
    }
}