.Container_prodotti{
    border: 1px solid black;
    padding: 5px;
    display: flex;
    flex-direction: column;
    height: 80%;
    background-color: white;
    overflow-y: scroll;
}

.container_forms{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    min-width: 90vw;
    height: 60vh;
}

.label_e_input{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
}

.totale_container{
    display: flex;
    flex-direction: row;
    justify-content: end;
    border-radius: 0px;
    height: 44px;
    width: 30%;
    align-items: center;
}

.lbl1{
    width:70%;
}

.inpt1{
    width:100%;
    border: none;
}
.inpt2{
    width:70%;
    border: none;
}

.inpt2_container{
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 0px;
    border: 1px solid #dfe1e5;
    height: 44px;
    width: 30%;
}

.prezzo_medio_btn{
    width: 44px;
    margin: 3px;
    background-color: lightgrey;
    border: none;
}

.Scheda_prodotto{
    border: 1px solid black;
    display: inline-flex;
    background: rgb(255,255,255);
    background: linear-gradient(117deg, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
    height: 70px;
    min-height: 70px;
}

.scheda_quantita{
    width: 20%;
    text-align: center;
    align-self: center;
}

.scheda_descrizione_container{
    width: 60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Descrizione{
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 3px 0px;
}

.Quantita{
    margin: 0px;
}

.tag_elimina{
    margin-bottom: 10px;
}

.Prezzo_totale{
    font-weight: bold;
}

.scheda_prezzo_elimina{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*  per rimuover le freccette dai campi input numerici*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*------------------------------------------------------*/

.Container_totale{
    border: 1px solid black;
    padding: 5px;
    display: flex;
    align-items: center;
    height: 7%;
}

.totale_testo{
    color:green;
}

.Btn_Aggiungi_prodotto{
    width: 100%;
    height: 44px;
}

.Btn_Modifica_prodotto{
    width: 100%;
    height: 44px;
}

button:disabled{
    background-color: transparent;
    border: 1px solid lightgray;
}

.form{
    width: 48%;
    height: 100%;
}

.indietro_container{
    height: 10%;
    text-align: end;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .form{
        width: 100%;
    }
    .container_forms{
        min-width: 100%;
        height: 100%;
        flex-direction: column;
    }
    .lbl1 {
        width: 50%;
    }
    .inpt2_container{
        width: 50%;
    }     
    .totale_container{
        width: 50%;
    }
    .Container_prodotti{
        overflow-y: visible;
        height: auto;
    }
}