body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  padding-bottom: 20px;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.Area_btn{
  width: 200px;
  height: 80px;
  border: solid black 1px;  
  background-color: white;
  margin: 5px;
  display: flex;
  justify-content: center;
}

.Area_btn > h3{
  margin:0px;
  margin-bottom: 5px;
  align-self: center;
  line-height: 10px;
}

.Modalita_container{
  display: flex;
  padding: 10px;
  justify-content: center;
}

.Opzione_modalita{
  font-weight: normal;
  padding: 5px;
  background-color: lightgray;
  width: 120px;
}

.Selezionata{
  background-color: green;
  color: white;
  font-weight: bold;
}

.Opzioni{
    vertical-align: top;
    margin: 10px;
    flex-grow: 2;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Anteprima{
  display: contents;
  margin: 10px;
  flex-grow: 4;
  width: 500px;
}

.Laterale{
  display: inline-block;
  flex-grow: 2;
  min-width: 200px;
  margin: 10px;
}

.Laterale::after{
  content: "";
  display: inline-block;
  width: 200px;  
}

.flexContainer{
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  margin-top: 30px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .Modalita_container{
    border: none;
    padding: 0;
    box-shadow: 1px 1px 10px #888888
  }
  .Opzione_modalita{
    width: 50%;
    padding: 10px;
  }
}

.Sfondo_blur{
    z-index: 110;
}
.Sfondo_blur{
    background-color: rgba(50,50,50,0.7);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Finestra_container{
    background-color: #F6F9FF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--Font);
    padding: 30px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: scroll;


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.Finestra_container::-webkit-scrollbar {
    display: none;
}
  

.label_input{
    font-weight:bold;
    margin:5px;
}

.input_text{
    -webkit-appearance: none;
    font-style: italic;
    margin-bottom: 10px;
    width: -webkit-fill-available;
    height: 44px;
    border-radius: 0px;
    border: 1px solid #dfe1e5;
    outline: none;
    padding: 0px 15px;
}

.input_text:focus {    
    border: 1px solid #dfe1e5;
    outline: none;
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }

.input_number{
    font-style: italic;
    margin: auto;
}

.conferma_btn{
    font-weight: bold;
}

h1{
    margin-top: 0px;
}

@media screen and (max-width: 600px) {
    .Finestra_container{
        height: 100%;
        width: 100%;
    }

    h1{
        margin-top: revert;
    }
}

.Container_prodotti{
    border: 1px solid black;
    padding: 5px;
    display: flex;
    flex-direction: column;
    height: 80%;
    background-color: white;
    overflow-y: scroll;
}

.container_forms{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    min-width: 90vw;
    height: 60vh;
}

.label_e_input{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
}

.totale_container{
    display: flex;
    flex-direction: row;
    justify-content: end;
    border-radius: 0px;
    height: 44px;
    width: 30%;
    align-items: center;
}

.lbl1{
    width:70%;
}

.inpt1{
    width:100%;
    border: none;
}
.inpt2{
    width:70%;
    border: none;
}

.inpt2_container{
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 0px;
    border: 1px solid #dfe1e5;
    height: 44px;
    width: 30%;
}

.prezzo_medio_btn{
    width: 44px;
    margin: 3px;
    background-color: lightgrey;
    border: none;
}

.Scheda_prodotto{
    border: 1px solid black;
    display: inline-flex;
    background: rgb(255,255,255);
    background: linear-gradient(117deg, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
    height: 70px;
    min-height: 70px;
}

.scheda_quantita{
    width: 20%;
    text-align: center;
    align-self: center;
}

.scheda_descrizione_container{
    width: 60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Descrizione{
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 3px 0px;
}

.Quantita{
    margin: 0px;
}

.tag_elimina{
    margin-bottom: 10px;
}

.Prezzo_totale{
    font-weight: bold;
}

.scheda_prezzo_elimina{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*  per rimuover le freccette dai campi input numerici*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*------------------------------------------------------*/

.Container_totale{
    border: 1px solid black;
    padding: 5px;
    display: flex;
    align-items: center;
    height: 7%;
}

.totale_testo{
    color:green;
}

.Btn_Aggiungi_prodotto{
    width: 100%;
    height: 44px;
}

.Btn_Modifica_prodotto{
    width: 100%;
    height: 44px;
}

button:disabled{
    background-color: transparent;
    border: 1px solid lightgray;
}

.form{
    width: 48%;
    height: 100%;
}

.indietro_container{
    height: 10%;
    text-align: end;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .form{
        width: 100%;
    }
    .container_forms{
        min-width: 100%;
        height: 100%;
        flex-direction: column;
    }
    .lbl1 {
        width: 50%;
    }
    .inpt2_container{
        width: 50%;
    }     
    .totale_container{
        width: 50%;
    }
    .Container_prodotti{
        overflow-y: visible;
        height: auto;
    }
}
.notaContainer{
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 500px;
}

.areaNota{
    padding: 15px;
    resize: none;
}

.btnAggiungi{
    width: 180px;
    align-self:center;
    border: 1px solid black;
    justify-content: center;
}
.btnElimina{
    width: 10%;
    height: 44px;
}
.selectNota{
    width: 90%;
    padding: 5px;
}

.btnNoteContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.radio_container{
    padding-top: 20px;
}
@media only screen and (max-width: 600px) {
    .notaContainer{
        width: auto;
    }
}
.main_container{
    text-align: left;
    height: 80vh;
    width: 70vw;
}

.Container_risultati{
    border: 1px solid black;
    padding: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 55%;
    width:auto;
}

.Scheda_risultato{
    border: 1px solid black;
    display: inline-flex;
    background: rgb(255,255,255);
    background: linear-gradient(117deg, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
    height: 70px;
    min-height: 70px;
    margin-top: 4px;
    padding: 10px 0px;
}

.scheda_numero{
    width: 20%;
    text-align: center;
    align-self: center;
    border-right: solid black 1px;
}

.scheda_descrizione_container{
    width: 60%;
    text-align: left;
    padding-left: 5px;
}

.Descrizione{

}

.scheda_valore{
    width: 20%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

input{
    display: flex;
}

.input_anno{
    height: 44px;
    padding: 5px;
    margin-left: 10px;
    border: 1px solid #dfe1e5;
}

.btn_chiudi{
    margin-top: 10px;
    width: 100%;
    height: 35px;
}

@media only screen and (max-width: 600px) {
    .main_container{
        width: 100%;
        height: 100%;
    }

    .Finestra_container {
        padding: 15px; 
    }
}
.form_container{
    display: inline-grid;
    padding:5px;
}

.container_btns{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    margin: 15px 0px;
}

.pdf_btn_download{
    height: 44px;
    background-color: rgb(161, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    width: 120px;
}

.pdf_btn_download a{
    text-decoration: none;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.btn_invia_mail{
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    font-weight: bold;
    width: 120px;
    border: 2px solid dodgerblue;
    color: white;
    background: dodgerblue;
}

.btn_chiudi{
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    font-weight: bold;
    width: 120px;
    background: transparent;
    color: black;
    border: 2px solid;
    margin: 0px
}

.btn_salva_mail_predefinito{
    height: 44px;
    width: 200px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .container_btns{
        flex-direction: column-reverse;
        align-items: center;

    }

    .container_btns button, .container_btns div {
        margin: 15px;
        width: 60%;
    }
}
.Alert_container_blur{
    background-color: rgba(50,50,50,0.7);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:200
}

.Alert_container{
    background-color: #F6F9FF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--Font);
    padding: 30px;
}

.container_btn{
    display: inline-flex;
}

.btn{
    width: 80px;
    height: 35px;
    margin: 10px;
}

.btn:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    /* Lowering the shadow */
}

.btn_ok{
    background-color: rgb(164, 229, 164);
    font-weight: bold;
}

.btn_modifica{
    background-color: rgb(255, 214, 0);
}

.btn_annulla{
    background-color: rgb(255, 255, 255);
    border: solid 1px black;
}

.Attenzione{
    border: 2px solid red;
    color: red;
}

.container_anteprima_mail{

    border: 1px solid black;
}
.Menu_container{
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
    flex-direction: row;
}

.Menu_option_btn{
    background-color: white;
    width: 160px;
    height: 35px;
    border-radius: 0px;
    margin: 3px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid black;
}

.Titoli_Menu{
  font-weight: bold;
  margin-left: 5px;
  font-size: 17px;
  color: black;
}

.custom-file-input {
    color: transparent;
    width: inherit;
    height: inherit;
    font-weight: bold;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFCSURBVEiJ3dRLK0VRFAfw3+UqmXhNxNDMzEQ+gIGhfAEzUcqEDHwAmZgZyEAxkqkvoBjJYyBlgBFSyiN53OIanHPqtDv3ct0z4V+rtR+r/3/vtdbe/HUUYt8eW4g7PNUrMosPlDOshLF6BR4rkCd2Vg95ISaphgeMfxPzjB28ZG1WO30tdoSmSjcoYQrbePvmtGk0YgCr6EY/jtMBxdivYA0zaKtBoIRlzGEDzWFAInCKPizE83dc/VDkFIe4kHH7YmrcEPtlUeu+/lAgQW/WYjGY72Na1DWjNQqEuMNSUuRJ7Iq64AMHGeK/wWWa5ESUpr2cyOGlIViYwGBO5DBPlKIhdOFefg9vLVHZiv1mjuTXUr9zD4ZzJC9jJJ2nFpznSL4eFmIxR/IbdIQCtzmRf4apIfpNO9EabvwCb6Li/jN8AdgfwpDysixXAAAAAElFTkSuQmCC) 'Apri file';
  color: black;
  font-size: 17px;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-size: 10pt;
  text-align: center;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.MostraNascondiContainer{
  border-bottom: 1px solid black;
  text-align: right;
  padding-right: 15px;
  background-color: white;
}

.Menu_btn{
  display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .Menu_container{
        background-color: white;
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }

    .Menu_option_btn{
      border: 0px;
      width: 100%;
      margin-top: 15px;
      text-align: left;
      justify-content: left;
    }

    .custom-file-input{
      margin-left: 6px;
    }

    .custom-file-input::before {
      width: 0px;
    }
}
.Pagina{
    height: 90vh;
    width:calc(90vh/1.41) ;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 10px;
    background-color: white;
    border: 1px black solid;
}

.intestazione {
    height: 15%; 
    width: 100%;
    display: flex;
    border-bottom: 1px solid black;
}

.logoContainer {
    height:85%;
}

.logo{
    height: 100%;
    width: auto;
}

.infoAziendaContainer{
    text-align: right;  
    flex-direction: row;           	
    width: 100%;
    line-height: 0.8;
}

.nomeAzienda{
    font-family: Helvetica;
    font-weight: bold;
    font-size: 16px;
    margin-top: 0px;
}

.datiFatturaContainer {
    width: 100%;
    display: flex;
    border-bottom: 1px solid black;
    padding-top: 20px;
    padding-bottom: 20px;
}

.datiFattura {
    width:50%; 
    text-align: left;
}

.datiCliente {
    width:50%; 
    text-align: right;
}

.regular{
    font-family: Helvetica;
    font-size:  12px;
    font-weight:100
}

.bold{
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px;
}

/* --- Tabella ---*/
.tabellaContainer {
    height: 60%; 
    width: 100%;
    display: flex;
    flex-direction: column;
}

.head {
    flex-direction: row; 
    display: flex;
    width: 100%;
    font-weight: bold;
}

.descrizione{
    width: 60% ;
    text-align: left;
}

.quantita{
    width: 10% ;
    text-align: center;
}

.prezzoU{
    width: 15%;
    text-align: center;
}

.prezzoT{
    width: 15%;
    text-align: center;
}

.row{
    display: flex;
    width: 100%;
    flex-direction:row;
    background-color: rgba(240,240,240,1); 
    border-bottom: 1px solid black;
    overflow: hidden;
    height: 22px;
}

.lightrow{
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: white;
    border-bottom: 1px solid black 
}

.resoconto {width: 100%; 
    flex-direction: row;
    display: flex;
    justify-content: end;
    border-top: 1px solid black;
}

.vuoto {width: 60%; flex-direction: row;
}

.resocontoDescrizioni{width: 30%; text-align:left;
}

.resocontoImporti {width: 10%; text-align: right;
}
/*-------------------------------------------*/

.noteContainer{
    height: 11%;
    width: 100%;
    bottom: 20px;
    text-align: left;
    word-wrap: break-word;
}

.piePagina{
    height: 7%;
    bottom: 0px; 
    width: 100%;
    border-top: 1px solid black;
    margin-bottom: 0px; 
    text-align: justify;

}

p{
    margin: 0px;
    line-height: 1;
}

/*---------------------MOBILE----------------------*/

@media screen and (max-width: 600px) {
    .resocontoDescrizioni{
        width: 40%;
    }
    .resocontoImporti{
        width: 20%;
    }
    .head{
        font-size: 9pt;
    }
}
