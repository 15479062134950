.App {
  text-align: center;
  padding-bottom: 20px;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.Area_btn{
  width: 200px;
  height: 80px;
  border: solid black 1px;  
  background-color: white;
  margin: 5px;
  display: flex;
  justify-content: center;
}

.Area_btn > h3{
  margin:0px;
  margin-bottom: 5px;
  align-self: center;
  line-height: 10px;
}

.Modalita_container{
  display: flex;
  padding: 10px;
  justify-content: center;
}

.Opzione_modalita{
  font-weight: normal;
  padding: 5px;
  background-color: lightgray;
  width: 120px;
}

.Selezionata{
  background-color: green;
  color: white;
  font-weight: bold;
}

.Opzioni{
    vertical-align: top;
    margin: 10px;
    flex-grow: 2;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Anteprima{
  display: contents;
  margin: 10px;
  flex-grow: 4;
  width: 500px;
}

.Laterale{
  display: inline-block;
  flex-grow: 2;
  min-width: 200px;
  margin: 10px;
}

.Laterale::after{
  content: "";
  display: inline-block;
  width: 200px;  
}

.flexContainer{
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  margin-top: 30px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .Modalita_container{
    border: none;
    padding: 0;
    box-shadow: 1px 1px 10px #888888
  }
  .Opzione_modalita{
    width: 50%;
    padding: 10px;
  }
}
