.Menu_container{
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
    flex-direction: row;
}

.Menu_option_btn{
    background-color: white;
    width: 160px;
    height: 35px;
    border-radius: 0px;
    margin: 3px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid black;
}

.Titoli_Menu{
  font-weight: bold;
  margin-left: 5px;
  font-size: 17px;
  color: black;
}

.custom-file-input {
    color: transparent;
    width: inherit;
    height: inherit;
    font-weight: bold;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: url(../img/folder.png) 'Apri file';
  color: black;
  font-size: 17px;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-size: 10pt;
  text-align: center;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.MostraNascondiContainer{
  border-bottom: 1px solid black;
  text-align: right;
  padding-right: 15px;
  background-color: white;
}

.Menu_btn{
  display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .Menu_container{
        background-color: white;
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }

    .Menu_option_btn{
      border: 0px;
      width: 100%;
      margin-top: 15px;
      text-align: left;
      justify-content: left;
    }

    .custom-file-input{
      margin-left: 6px;
    }

    .custom-file-input::before {
      width: 0px;
    }
}